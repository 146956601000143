var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "任务名称", prop: "jobName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入任务名称" },
                model: {
                  value: _vm.form.jobName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "jobName", $$v)
                  },
                  expression: "form.jobName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务分组", prop: "jobGroup" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.jobGroup,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "jobGroup", $$v)
                    },
                    expression: "form.jobGroup",
                  },
                },
                _vm._l(_vm.jobGroupOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "invokeTarget" } },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(" 调用方法  "),
                  _c(
                    "a-popover",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("p", [
                          _vm._v("Class类调用示例："),
                          _c("code", [
                            _vm._v(
                              "auto.revolution.platform.task.YwTask.ywParams('yw')"
                            ),
                          ]),
                        ]),
                        _c("p", [_vm._v("参数说明：")]),
                        _c("p", [
                          _vm._v(
                            "1.支持字符串，布尔类型，长整型，浮点型，整型"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "2.参数是字符串时，参数值需以单引号引用，如ywTask.ywParams('yw')"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "3.参数是布尔类型时，参数值需是true或false，不用单引号或双引号，如ywTask.ywParams('yw')"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "4.参数是长整型时，参数值需以L字母结尾，不用单引号或双引号，如ywTask.ywParams(5769L)"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "5.参数是浮点型时，参数值需以D字母结尾，不用单引号或双引号，如ywTask.ywParams(0.678D)"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "6.参数是整型时，参数值只能包含整数数字，不用单引号或双引号，如ywTask.ywParams(678)"
                          ),
                        ]),
                      ]),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(" Bean调用示例："),
                        _c("code", [_vm._v("ywTask.ywParams('yw')")]),
                      ]),
                      _c("a-icon", { attrs: { type: "question-circle-o" } }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("a-input", {
                attrs: { placeholder: "请输入调用目标字符串" },
                model: {
                  value: _vm.form.invokeTarget,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invokeTarget", $$v)
                  },
                  expression: "form.invokeTarget",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "cron表达式", prop: "cronExpression" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入cron执行表达式" },
                model: {
                  value: _vm.form.cronExpression,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cronExpression", $$v)
                  },
                  expression: "form.cronExpression",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否并发", prop: "concurrent" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.concurrent,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "concurrent", $$v)
                    },
                    expression: "form.concurrent",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "0" } }, [
                    _vm._v("允许"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "1" } }, [
                    _vm._v("禁止"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "misfirePolicy" } },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(" 错误策略  "),
                  _c(
                    "a-popover",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("p", [
                          _vm._v(
                            "立即执行：所有未触发的执行都会立即执行，然后触发器再按计划运行。"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "执行一次：无论错过了多少次触发器的执行，都只立即执行一次，然后触发器再按计划运行。"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "放弃执行：所有未触发的执行都丢弃掉，然后在触发器的下一个调度周期按计划运行。"
                          ),
                        ]),
                      ]),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("类型说明："),
                      ]),
                      _c("a-icon", { attrs: { type: "question-circle-o" } }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.misfirePolicy,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "misfirePolicy", $$v)
                    },
                    expression: "form.misfirePolicy",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "1" } }, [
                    _vm._v("立即执行"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "2" } }, [
                    _vm._v("执行一次"),
                  ]),
                  _c("a-radio-button", { attrs: { value: "3" } }, [
                    _vm._v("放弃执行"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }